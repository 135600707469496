import { DateInput } from "@components/DateInput";
import { Input } from "@components/Input";
import { useContext, useEffect, useReducer, useRef, useState } from "react";
import { useHttpsCallable } from "react-firebase-hooks/functions";
import { useForm } from "react-hook-form";
import { useTranslation } from "@helpers/useTranslation";
import { FirebaseContext } from "src/helpers/firebase";
import { BASE_STORAGE_PATH, usePersistedState } from "src/helpers/usePersistedState";
import { useToast } from "src/helpers/useToast";
import * as Sentry from "@sentry/react";
import { PremiumModal } from "@components/PremiumModal";
import { signInWithCustomToken } from "firebase/auth";
import { useBranding } from "src/helpers/useBranding";
import useFormPersist from "react-hook-form-persist";
import { TopNav } from "@components/TopNav";

const eventTypes = ["wedding", "birthday", "other"];

export const AddEvent = () => {
  const dateInputRef = useRef<HTMLInputElement | null>(null);
  const handleShowPicker = () => dateInputRef?.current?.showPicker && dateInputRef.current.showPicker();

  const { t, i18n } = useTranslation("register");
  const {
    register,
    watch,
    getValues,
    setValue,
    reset,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm();
  useFormPersist(`${BASE_STORAGE_PATH}-addevent`, { watch, setValue });
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);

  const toast = useToast();
  const { auth, functions } = useContext(FirebaseContext);
  const [appData, setAppData] = usePersistedState();
  const [eventId, setEventId] = useState<string | undefined>();
  const [uid, setUid] = useState<string | undefined>();
  const [skipPayment, setSkipPayment] = useState<boolean | undefined>(false);

  const [addNewEvent, isSubmitting, error] = useHttpsCallable(functions, "addNewEvent");
  const [loading, setLoading] = useState(false);
  const { brand } = useBranding();

  const onSubmit = async (data) => {
    const usedReservedWordForEventId = ["admin", "events", "login", "register", "forgotpassword", "contact"].includes(
      data.eventId,
    );
    if (usedReservedWordForEventId) {
      toast(t("usedReservedWord"), "🤷‍♂️", "error");
      setError("eventId", { message: t("usedReservedWord") });
      return;
    }
    try {
      const { date, prepopulate, sendNews, pin, name, eventName, type } = data;
      const eventId = data.eventId.toLowerCase();
      const prepopulateLang = i18n.language === "lt" ? "lt" : "en";

      setLoading(true);
      const response = (await addNewEvent({
        brand,
        sendNews,
        prepopulate,
        prepopulateLang,
        type,
        eventId,
        pin,
        name,
        eventName,
        date,
      })) as any;
      setEventId(eventId);
      const { token, uid: userUid, skipPayment } = response?.data ?? {};

      if (userUid) {
        await signInWithCustomToken(auth, token);
        setUid(userUid);
        setSkipPayment(skipPayment);
        reset();
        try {
          Sentry.captureMessage(`Event added: ${eventId}`);
        } catch {
          console.error("Sentry failed to capture message");
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (eventId && uid) {
      setAppData({ ...appData, uid, isAdmin: true }, eventId);

      // Payments will be skipped for subscribers (monthly payments handled on case-by-case manual basis)
      if (skipPayment) {
        window.location.href = `/${eventId}/admin`;
      } else {
        setShowPremiumModal(true);
      }
    }
  }, [eventId, uid]);

  useEffect(() => {
    if (error) {
      if (error.message.includes("already exists")) {
        toast(`${t("handleExists1")} '${eventId}' ${t("handleExists2")}`, "🤷‍♂️", "error");
      } else {
        toast(t("creationFailed"), "🤷‍♂️", "error");
      }
      console.error("Event creation failed!", error);
    }
  }, [error]);

  const { homepage } = useBranding();

  // ensure event handle is lowercase and remove special characters except dashes
  const eventIdField = watch("eventId");
  useEffect(() => {
    if (eventIdField) {
      let value = eventIdField.toLowerCase();
      value = value.replace(/[^a-z0-9-]/g, "");
      setValue("eventId", value);
    }
  }, [eventIdField]);

  // ensure pin is only numbers
  const pinField = watch("pin");
  useEffect(() => {
    if (pinField) {
      const value = pinField.replace(/[^0-9]/g, "");
      setValue("pin", value);
    }
  }, [pinField]);

  const [showPremiumModal, setShowPremiumModal] = useState(false);

  return (
    <>
      <PremiumModal show={showPremiumModal} redirectBackUri={`/${eventId}/admin`} fallbackEventId={eventId} />
      <TopNav isAdmin={true} showBack />
      <main className="mt-12 flex flex-col items-center px-6">
        <div className="flex flex-col justify-center">
          <h1 className="text mb-4 w-full px-4 pt-8 text-center text-4xl text-2xl font-semibold ">
            {t("addEventDetails")}
          </h1>
          <p className="text-md mt-4 mb-6 max-w-2xl self-center text-center">{t("aboutAddEvent")}</p>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="h-100 flex flex-col justify-center px-4">
          <div className="flex flex-col justify-center">
            <div className="align-start mb-2 flex flex-row" onClick={() => handleShowPicker()}>
              <DateInput
                ref={dateInputRef}
                label={t("dateField")}
                name="date"
                register={register}
                errors={errors}
                requiredMessage={t("fieldRequired")}
                required
              />
            </div>
            <div className="align-start flex flex-row">
              <Input
                label={t("eventHandle")}
                name="eventId"
                required
                placeholder={t("eventHandleExample")}
                title={t("handleValidationError")}
                pattern="^[a-zA-Z0-9-]+$"
                requiredMessage={t("fieldRequired")}
                errors={errors}
                register={register}
                tooltip={t("handleTooltip")}
              />
            </div>
            <span className="mt-2" />
            <Input
              label={t("eventName")}
              name="eventName"
              required
              placeholder={t("eventNameExample")}
              requiredMessage={t("fieldRequired")}
              errors={errors}
              register={register}
            />
            <span className="mt-2" />
            <div className="align-start flex flex-row">
              <Input
                label={t("pinCode")}
                name="pin"
                placeholder={t("pinCodeExample")}
                pattern="^\d{4}$"
                title={t("pinCodeValidationError")}
                maxLength={4}
                required
                requiredMessage={t("fieldRequired")}
                errors={errors}
                register={register}
                tooltip={t("pinCodeTooltip")}
              />
            </div>
            <div className="mb-4 mt-4 flex w-full flex-col">
              <label className="label">
                <span className="label-text">
                  {t("eventType")}
                  <span className="text-warning">{` *`}</span>
                </span>
              </label>
              <select className="select-bordered select" required {...register("type", { required: true })}>
                {eventTypes.map((type) => (
                  <option value={type}>{t(type)}</option>
                ))}
                {errors["type"] && <span className="pl-1 pt-1 text-xs text-warning">{t("requiredMessage")}</span>}
              </select>
            </div>
            <div className="form-control mt-4 w-full">
              <label className="label cursor-pointer">
                <span className="label-text mr-3 text-sm">{t("prepopulate")}</span>
                <input
                  type="checkbox"
                  className="toggle-primary toggle"
                  checked={getValues(`prepopulate`)}
                  onClick={(e) => {
                    const value = (e.target as any).checked;
                    setValue(`prepopulate`, value);
                    forceUpdate();
                  }}
                />
              </label>
            </div>

            <div className="form-control mt-12 mb-6">
              <label className="label cursor-pointer">
                <span className="label-text text-sm">{t("subscribe")}</span>
                <input type="checkbox" className="checkbox-primary checkbox" {...register("sendNews")} />
              </label>
            </div>

            <button
              type="submit"
              disabled={isSubmitting || loading}
              className={`btn-primary btn mb-6 max-w-sm px-4 ${isSubmitting ? "loading" : ""}`}
            >
              {t("addNewEvent")}
            </button>
          </div>
        </form>
        <div className="text-muted max-w-xs px-4 text-center text-2xs">
          <span>{t("confirmThatYouRead")}</span>{" "}
          <a className="underline" href={`${homepage}/terms`}>
            {t("terms")}
          </a>{" "}
          <span>{t("and")}</span>{" "}
          <a className="underline" href={`${homepage}/privacy`}>
            {t("privacyPolicy")}
          </a>
        </div>
        <div className="mb-24" />
      </main>
    </>
  );
};
